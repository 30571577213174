<template>
  <div class="pages">
    <div class="banner-houses">
      <div class="banner-info">
        <div class="banner">
          <el-carousel indicator-position="none" @change="handleBannerChange" ref="banner">
            <el-carousel-item v-for="item in project.banner_image" :key="item">
              <div class="images" @click="handleCarouselCLick(item)">
                <img :src="item" alt="">
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="banner-thumb-wrapper">
          <div class="banner-thumb">
            <div class="thumb" :style="{marginLeft:index === 0 ? `${firstBannerThumbMarginLeft}px`:0}"
                 :class="{active:index === bannerIndex}" v-for="(item,index) in project.banner_image"
                 :key="item">
              <img :src="item" class="">
              <span class="triangle"></span>
            </div>
          </div>
          <div class="left-pre" @click="handleBannerPrev">
            <img src="/static/images/left_pre.png">
          </div>
          <div class="right-pre" @click="handleBannerNext">
            <img src="/static/images/right_pre.png">
          </div>
        </div>
      </div>
      <div class="houses-info">
        <div class="title">
          <span>{{ project?.name }}</span>
        </div>
        <div class="tag-list">
          <div class="tag orange" v-if="project?.base_data?.primary_tag">
            <span>{{ project?.base_data?.primary_tag }}</span>
          </div>
          <div class="tag blue" v-if="project?.base_data?.product_status_id">
            <span v-if="project?.base_data?.product_status_id === 1">全城热销中</span>
            <span v-if="project?.base_data?.product_status_id === 2">火爆加推</span>
            <span v-if="project?.base_data?.product_status_id === 3">预售进行中</span>
            <span v-if="project?.base_data?.product_status_id === 4">待售</span>
          </div>
          <div class="tag gray" v-if="project?.base_data?.secondary_tags.length">
                <span v-for="(secondary_tag,secondary_tag_index) in project?.base_data?.secondary_tags"
                      :key="secondary_tag_index">{{ secondary_tag.tag }}</span>
          </div>
        </div>
        <div class="price-info">
          <div class="tit" style="width: 60px;">
            <span>价格</span>
          </div>
          <div class="price">
            <span>{{ project?.base_data?.price_pending == 1 ? '待定' : project?.base_data?.price_start }}</span>
          </div>
          <div class="tit" v-show="project?.base_data?.price_pending != 1">
            <span>元/㎡</span>
          </div>
        </div>
        <div class="desc-info">
          <div class="tit">
            <span>开盘</span>
          </div>
          <div class="desc">
            <span>{{ project?.base_data?.open_pending === 1 ? '待定' : project?.base_data?.open_date }}</span>
          </div>
        </div>
        <!--        <div class="desc-info">-->
        <!--          <div class="tit">-->
        <!--            <span>交房</span>-->
        <!--          </div>-->
        <!--          <div class="desc">-->
        <!--            <span>2022年07月29日</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="desc-info">
          <div class="tit">
            <span>户型</span>
          </div>
          <div class="desc">
            <span>{{ project?.base_data?.types_for_sale }}</span>
          </div>
          <div class="desc-img" @click="handleShowClick">
            <img src="/static/images/hx_ico.png" alt="">
            <span>全部户型</span>
          </div>
        </div>
        <div class="desc-info">
          <div class="tit">
            <span>地址</span>
          </div>
          <div class="desc">
            <span>{{ project?.base_data?.address }}</span>
          </div>
          <div class="desc-img" @click="handleMapClick">
            <img src="/static/images/dw_ico1.png" alt="">
            <span>查看地图</span>
          </div>
        </div>
        <div class="report-info">
          <div class="btn" @click="handleProjectReportClick" :class="{disable:project?.can_report !== 1}">
            <span>报备</span>
          </div>
          <div class="like" @click="handleLikeClick">
            <img v-show="project.collect" src="/static/images/ax_ico2.png" alt="">
            <img v-show="!project.collect" src="/static/images/ax_ico1.png" alt="">
          </div>
          <div class="share-info">
            <div class="item" @click="handleFdjsqClick">
              <img src="/static/images/jsq_ico.png" alt="">
              <span>房贷计算器</span>
            </div>
            <div class="item" @click="handleShareClick">
              <img src="/static/images/fx_ico.png" alt="">
              <span>分享</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <fast-report v-model:show="report_show" :project_id="project_id" @afterSubmit="handleAfterSubmit"></fast-report>
    <report-result v-model:show="report_result_show" v-model:report-type="report_result_type"
                   @close="handleReportResultClose"></report-result>

    <el-dialog
        custom-class="shareCanvasDialog"
        v-model="shareDialogShow"
        title="分享"
        width="400px"
        @opened="handleShareCanvasDialogOpened"
    >
      <canvas id="shareCanvas" width="400" height="640"></canvas>
    </el-dialog>
  </div>
</template>

<script>
import FastReport from '../../../components/FastReport/FastReport'
import ReportResult from '../../../components/ReportResult/ReportResult'

export default {
  name: 'HousesHome',
  components: {FastReport, ReportResult},
  data() {
    return {
      project: false,
      bannerIndex: 0,
      firstBannerThumbMarginLeft: 0,
      project_id: null,
      report_show: false,
      report_result_show: false,
      report_result_type: 'success',
      shareDialogShow: false,
      shareWasDraw: false,
    }
  },
  inject: ['login'],
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      let res = await this.http({
        url: '/api/report/projectInfo',
        method: 'GET',
        params: {
          project_id: this.$route.query.id
        }
      })

      res.data.base_data.primary_tag = res.data.base_data.primary_tags[0]?.tag || null

      let memberInfo = JSON.parse(localStorage.getItem('MEMBER') || '{}')
      if (memberInfo.can_receptionist !== undefined && !memberInfo.can_receptionist) {
        res.data.can_report = 0
      }

      this.project = res.data
    },
    async handleShareCanvasDialogOpened() {
      if (this.shareWasDraw) {
        return
      }
      try {
        this.shareWasDraw = true

        let res = await this.http({
          url: '/api/report/projectShare',
          method: 'GET',
          params: {
            project_id: this.project.id
          }
        })

        let imagePoster = await this.loadImage(res.data.poster)
        let imageSunCode = await this.loadImage(res.data.sun_code)

        let canvas = document.getElementById('shareCanvas')
        let ctx = canvas.getContext('2d')
        ctx.fillStyle = '#ffffff'
        ctx.fillRect(0, 0, 400, 640)
        ctx.drawImage(imagePoster, 0, 0, 400, 500)
        ctx.drawImage(imageSunCode, 40, 520, 100, 100)
        ctx.fillStyle = '#222222'
        ctx.font = '30px Microsoft YaHei'
        ctx.fillText('论楼lunlou', 160, 580)
      } catch (e) {
        this.shareWasDraw = false
        console.log(e)
      }
    },
    loadImage(url) {
      //创建img标签
      let img = new Image()
      img.src = url
      //跨域选项
      img.setAttribute('crossOrigin', 'Anonymous')
      return new Promise((resolve) => {
        //文件加载完毕
        img.onload = function () {
          resolve(img)
        }
      })

    },
    handleBannerChange(e) {
      this.bannerIndex = e
      if (this.project.banner_image.length <= 4) {
        this.firstBannerThumbMarginLeft = 0
        return
      }

      if (e < this.project.banner_image.length - 3) {
        this.firstBannerThumbMarginLeft = -(e * 116)

      } else {
        this.firstBannerThumbMarginLeft = -((this.project.banner_image.length - 4) * 116)
      }
    },
    handleBannerPrev() {
      this.$refs['banner'].prev()
    },
    handleBannerNext() {
      this.$refs['banner'].next()
    },
    handleMapClick() {
      this.$router.push({
        path: '/HousesSurrounding',
        query: {
          id: this.$route.query.id
        }
      })
    },
    handleShowClick() {
      this.$router.push({
        path: '/HousesShow',
        query: {
          id: this.$route.query.id
        }
      })
    },
    handleProjectReportClick() {
      if (!this.project.can_report) {
        return
      }

      let member_id = localStorage.getItem('MEMBER_ID')

      if (!member_id) {
        this.login()
        return
      }


      let memberInfo = JSON.parse(localStorage.getItem('MEMBER') || '{}')
      if (memberInfo.can_receptionist !== undefined && !memberInfo.can_receptionist) {
        this.project.can_report = 0
        return
      }

      this.project_id = this.project.id
      this.report_show = true
    },
    handleAfterSubmit(e) {
      if (e.code === 0) {
        this.report_result_type = 'success'
        this.report_result_show = true
      } else {
        this.report_result_type = 'error'
        this.report_result_show = true
      }
    },
    handleShareClick() {
      this.shareDialogShow = true
    },
    handleFdjsqClick() {
      window.open('https://fangdai.xuenb.com/', '_blank')
    },
    handleReportResultClose() {
      this.report_show = false
    },
    async handleLikeClick() {
      if (this.project.collect) {
        await this.http({
          url: '/api/report/projectUnCollect',
          method: 'POST',
          data: {
            project_id: this.project.id
          }
        })
      } else {
        await this.http({
          url: '/api/report/projectCollect',
          method: 'POST',
          data: {
            project_id: this.project.id
          }
        })
      }

      this.project.collect = !this.project.collect
    },
    handleCarouselCLick(item) {
      window.open(item, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
@import "HousesHome.scss";
</style>
